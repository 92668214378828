import { ChainId, JSBI } from '@daoswapdex/daoswap-dex-sdk'

// Node Type
export const USDT_DAO_PAIR_ADDRESS = {
  [ChainId.BSC_MAINNET]: '0x69217cF12bD760D6eA850A128646b21F7A61EE07',
  [ChainId.BSC_TESTNET]: '',
  [ChainId.HECO_MAINNET]: '0x1dbCac9E084A25d542893B14128a5910dF43a6b7',
  [ChainId.HECO_TESTNET]: ''
}
export const USDT_DAO_STAKING_REWARDS_ADDRESS = [
  '0xAe7184fcCCFc096f5F48Fffe384Cce8433FCE0E0',
  '0xb04161cba8501b14fF302F1800AA96453e75941c',
  '0x64521114F1d6cc11443A3323D62B78C1a6f219De',
  '0x79438C8e9660C98845E7Fc8A136D1ff92a75F3Ec',
  '0xcA691423C64fa6B24CD54e45353A236521D2C7f5',
  '0x8A041E9C28250A542642c65206Fa90E369Fe4409'
]
export const STAKING_LIMIT_FOR_LP_CONTRACT_ADDRESS = {
  [ChainId.BSC_MAINNET]: [
    '0x489cc30482499DA25057B78a9a64043a042BA421',
    '0x7137E710961a5754362932Cc9543C337cA1c9B0e',
    '0xf36Dd3A5100a2ce0014A78Cb40B5EfE30639eB5d',
    '0x739f5C2a5Ee387fFde600A72ed293431Bf7C6517',
    '0x271aFA5D75D2C090C8763FA29eeabaed94A7Bd16',
    '0xBD22E68ea007a21D313E34e0955B44F356273344',
    '0xe6D688b65EDA351D57a663f4578c1d8e3D9b7C48',
    '0xBE1162f12c32737Cf42a30eE34500db81cf96517',
    '0xe2931E97516BCd0a447bbF4594Da66f048B213B1',
    '0xf246606f83DFd8B221dad80A3304a02e07c500e3',
    '0x7a1162F1175eCB34048695E0628448c3F7FE2850',
    '0x401203b0ee5845D84e5E86d41d45c053739E6a59',
    '0x08841Ea961094bbfA0cdc282163c30713748fDe0',
    '0x8ad7fB2034161334F2ba46d708AaDfe5F8B4914D'
  ],
  [ChainId.BSC_TESTNET]: [''],
  [ChainId.HECO_MAINNET]: [
    '0xC6f7f50a18D1071FE08b4E3EEE4db7c8A49faEA0',
    '0xab5f0c81b2216c803AF6173d898a121314235dc5',
    '0x63f84cc9474f47f50784A98F498d0E236e982dc1',
    '0x655E6BD4742e04029a024dBA516b3ddb671AF017',
    '0xD21f4AE786aEFf05fC1717773DbA68aca2054D75',
    '0xE326AF702d311613C4614791b135E3703D15b948'
  ],
  [ChainId.HECO_TESTNET]: ['']
}
export const HECO_STAKING_RECORD_CONTRACT_ADDRESS = '0x9d27efE2E7a0493413a0136340812fA0603BA062'

export const NODE_TYPE_STELLAR_MIN_USD_VALUE = JSBI.multiply(
  JSBI.BigInt(10000),
  JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18))
) // 10000
export const NODE_TYPE_PLANETARY_MIN_USD_VALUE = JSBI.multiply(
  JSBI.BigInt(1000),
  JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18))
) // 1000
